$(document).ready(function () {
  $('.dropdown-submenu a.subMenu').on("click", function (e) {
    $(this).next('ul').toggle();
    e.stopPropagation();
    e.preventDefault();
  });
});

function myFunction() {
  var x = document.getElementById("myTopnav");
  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}

$('.bannerSlider').slick({
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  // easing: 'easeOutElastic',
  fade: true,
  prevArrow: false,
  nextArrow: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],

});
$('.offerSlider').slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  // arrows: true,
  prevArrow:"<span class='icon-leftarrow'></span>",
  nextArrow:"<span class='icon-rightarrow'></span>>",
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
 
});
$('.relatedproductSlider').slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  // arrows: true,
  prevArrow:"<span class='icon-leftarrow'></span>",
  nextArrow:"<span class='icon-rightarrow'></span>>",
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }, 
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
 
});
$('.applicationsSlider').slick({
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: '0',
  centerMode: true,
  variableWidth: true,
  variableHeight: true,
  prevArrow:"<span class='icon-leftarrow '></span>",
  nextArrow:"<span class='icon-rightarrow'></span>",
  responsive: [
    
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        centerMode: false,
        variableWidth: false,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        centerMode: false,
        variableWidth: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        variableWidth: false,
      }
    }
  ],
 
});
function setSlideVisibility() {
  //Find the visible slides i.e. where aria-hidden="false"
  var visibleSlides = $('.slick-slide[aria-hidden="false"]');
  //Make sure all of the visible slides have an opacity of 1
  $(visibleSlides).each(function() {
    $(this).css('opacity', 1);
  });
  //Set the opacity of the first and last partial slides.
  $(visibleSlides).first().prev().css('opacity', 0);
  $(visibleSlides).last().next().css('opacity', 0);
}

$(setSlideVisibility());

$('.applicationsSlider').on('beforeChange', function() {
  $('.slick-slide').each(function() {
  	$(this).css('opacity', 1);
  });
});
 
$('.applicationsSlider').on('afterChange', function() {
  setSlideVisibility();
});
$(function() {
  AOS.init();
});

document.getElementById("scrollArrow").addEventListener("click", scrollFunction);
function scrollFunction(){
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
} 

$(document).ready(function() {
  $('.fancybox').fancybox();
  $.fancybox.close();
});